if (typeof HTMLElement.prototype.wrap === "undefined") {
    HTMLElement.prototype.wrap = function (wrapper) {
        this.parentNode.insertBefore(wrapper, this);
        wrapper.appendChild(this);
        return wrapper;
    };
}

if (typeof HTMLElement.prototype.css === "undefined") {
    HTMLElement.prototype.css = function (styles) {
        for (const property in styles) {
            this.style[property] = styles[property];
        }
    };
}

if (typeof String.prototype.ucfirst === "undefined") {
    String.prototype.ucfirst = function () {
        if (this.length === 0) {
            return this;
        }
        return this.charAt(0).toUpperCase() + this.slice(1);
    };
}

if (typeof Array.prototype.empty === "undefined") {
    /**
     * Checks if the array is "empty" based on a set of criteria similar to PHP's empty() function.
     * An array is considered empty if it is physically empty or if all its elements are considered empty.
     * Elements are considered empty if they are either: 0, "", "0", null, false, or an empty array.
     *
     * @returns {boolean} Returns true if the array is considered empty, false otherwise.
     *
     * @example
     * // returns true
     * [].empty();
     *
     * @example
     * // returns true
     * [0, "", null, false, "0", []].empty();
     *
     * @example
     * // returns false
     * [0, 1, []].empty();
     */
    Object.defineProperty(Array.prototype, 'empty', {
        value: function() {
            if (this.length === 0) return true;
            return this.every(item =>
                item === 0 ||
                item === "" ||
                item === "0" ||
                item === null ||
                item === false ||
                (Array.isArray(item) && item.empty())
            );
        },
        configurable: true,
        writable: true,
        enumerable: false
    });
}

