export function userLocations({departmentType = null}) {
    return {
        userOrganizations: [],
        userLocations: [],
        chosenOrganizationId: null,
        chosenLocationId: null,
        chosenDepartmentId: null,
        loading: true,
        submitButtons: null,
        departmentType: departmentType,
        async init() {
            // Initialize the submit buttons
            this.submitButtons = this.$root.closest('form').querySelectorAll('.submit-button');
            if (!this.submitButtons.length) {
                console.warn('Alpine Warning: No form buttons with "submit-button" class were found.');
            }

            // Initially disable the submit buttons
            this.disableSubmitButtons();

            // Fetch user locations and process organizations
            await this.fetchUserLocations();
            this.processOrganizations();

            // Automatically select organization if only one is available
            this.autoSelectSingleOrganization();

            this.loading = false;
        },
        async fetchUserLocations() {
            this.userLocations = await this.$store.userLocationStore.getUserLocations();
        },
        processOrganizations() {
            const organizationMap = this.userLocations.reduce((acc, location) => {
                acc[location.organizationId] = location.organization;
                return acc;
            }, {});

            this.userOrganizations = Object.values(organizationMap).sort((a, b) => a.name.localeCompare(b.name));
        },
        autoSelectSingleOrganization() {
            if (this.userOrganizations.length === 1) {
                this.chosenOrganizationId = this.userOrganizations[0].id;
            }
        },
        disableSubmitButtons() {
            this.submitButtons.forEach(button => {
                if (!button.classList.contains('button--disabled')) {
                    button.classList.add('button--disabled');
                }
            });
        },
        enableSubmitButtons() {
            this.submitButtons.forEach(button => {
                if (button.classList.contains('button--disabled')) {
                    button.classList.remove('button--disabled');
                }
            });
        },
        getUserLocations() {
            return this.userLocations
                .filter(loc => loc.organizationId === this.chosenOrganizationId)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        getLocationDepartments() {
            const location = this.userLocations.find(loc => loc.id === this.chosenLocationId);
            if (!location) {
                return [];
            }

            const departments = location.departments.filter((dept) => {
                if (this.departmentType === null) {
                    return true;
                }
                return dept.hasOwnProperty(this.departmentType) && dept[this.departmentType] === true;
            });

            return departments.sort((a, b) => a.name.localeCompare(b.name));
        },
        departmentFieldRequired() {
            const departments = this.getLocationDepartments();
            return this.chosenLocationId === null || departments.length > 0;
        },
        getOrganizationById(organizationId) {
            return this.userOrganizations.find(org => org.id === organizationId);
        },
        handleChange() {
            this.disableSubmitButtons();
            if (this.chosenOrganizationId !== null && this.chosenLocationId !== null) {
                const departments = this.getLocationDepartments();
                if ((!departments.empty() && this.chosenDepartmentId !== null) || departments.empty()) {
                    this.enableSubmitButtons();
                }
            }
        }
    }
}
