export function productGallery({images = []}) {
    return {
        previewImages: images,
        image: images[0],
        updateImages(images) {
            this.previewImages = images;
            this.image = images[0];
        },
        isActiveImage(imageId) {
            return this.image.id === imageId;
        },
        chooseImage(imageId) {
            this.image = this.previewImages.find(e => e.id === imageId);
        }
    }
}

