export class NumberFormatter {
    static inEuro(number, locale = 'nl-NL') {
        return new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'}).format(number);
    }
}

/**
 * Executes an HTTP request with automatic method selection and content type handling. For 'POST' requests,
 * form data is sent as $_POST items. This function also includes enhanced error handling, optionally returning
 * JSON-parsed responses.
 *
 * @param {string} resource URL for the request.
 * @param {Object} [body={}] Body for 'POST' requests, as key-value pairs.
 * @param {string} [method='GET'] HTTP method ('GET' or 'POST').
 * @param {boolean} [returnJson=true] Whether to return a JSON-parsed response.
 *
 * @returns {Promise<Object|Response>} Promise resolving to JSON data or the response object.
 * @throws {Error} For non-ok responses, throws an error with a status-specific message.
 *
 * @example
 * // GET request expecting JSON
 * globalFetch('https://api.example.com/data')
 *   .then(console.log)
 *   .catch(console.error);
 *
 * @example
 * // POST request with form data, expecting a raw response
 * globalFetch('https://api.example.com/data', { key: 'value' }, 'POST', false)
 *   .then(response => response.text())
 *   .catch(console.error);
 */
export async function globalFetch(resource, body = {}, method = 'GET', returnJson = true) {
    let options = {method};

    if (Object.keys(body).length !== 0 && method === 'POST') {
        const formData = new FormData();

        // Function to recursively append data to FormData, properly handling arrays of objects for PHP
        function appendFormData(key, value, formData) {
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((subValue, index) => {
                        if (typeof subValue === 'object' && subValue !== null) {
                            Object.entries(subValue).forEach(([subKey, subVal]) => {
                                // Adjusted format for PHP
                                appendFormData(`${key}[${index}][${subKey}]`, subVal, formData);
                            });
                        } else {
                            // Append array values with their index for PHP
                            appendFormData(`${key}[${index}]`, subValue, formData);
                        }
                    });
                } else {
                    Object.entries(value).forEach(([subKey, subVal]) => {
                        // Handle nested objects
                        appendFormData(`${key}[${subKey}]`, subVal, formData);
                    });
                }
            } else {
                // Append primitive types directly
                formData.append(key, value);
            }
        }

        // Initialize the recursive appending process for each entry in the body
        Object.entries(body).forEach(([key, value]) => {
            appendFormData(key, value, formData);
        });

        options.body = formData;
    }

    const response = await fetch(resource, options);

    if (!response.ok) {
        let message = '';
        switch (response.status) {
            case 403:
                message = 'You are not authorized to fetch this data.';
                break;
            case 400:
                message = 'Your request cannot be processed.';
                break;
            default:
                message = 'An unknown error occurred.';
                break;
        }
        throw new Error(message);
    }

    return returnJson ? response.json() : response;
}

/**
 * Manages URL parameters, allowing for the getting, setting, and deleting of parameters in the URL.
 */
export class UrlParamManager {

    static instance;

    /**
     * Private or protected constructor to prevent direct construction calls with the `new` operator.
     * @param {string} url - The URL to manage. Defaults to the current window location.
     */
    constructor(url = window.location.href) {
        if (UrlParamManager.instance) {
            return UrlParamManager.instance;
        }

        this.url = new URL(url);
        UrlParamManager.instance = this;
    }

    /**
     * Retrieves the singleton instance of the UrlParamManager. If no instance has been created, it initializes a new one.
     * @param {string} url - The URL to manage. This parameter is only used the first time the singleton instance is created.
     * @returns {UrlParamManager} The singleton instance of the UrlParamManager.
     */
    static getInstance(url = window.location.href) {
        if (!UrlParamManager.instance) {
            UrlParamManager.instance = new UrlParamManager(url);
        }

        return UrlParamManager.instance;
    }

    /**
     * Retrieves the value of a URL parameter. Optionally applies a transformation callback to the value.
     * @param {string} key - The name of the parameter to retrieve.
     * @param {*} defaultValue - The default value to return if the parameter is not found. Defaults to null.
     * @param {Function|null} callback - An optional callback function to apply to the parameter's value before returning it.
     * @returns {*} The value of the parameter, the default value if the parameter is not found, or the transformed value if a callback is provided.
     */
    getParam(key, defaultValue = null, callback = null) {
        if (!this.url.searchParams.get(key)) {
            return defaultValue;
        }

        let value = this.url.searchParams.get(key);

        if (callback) {
            return callback(value);
        }

        return value;
    }

    /**
     * Updates or adds a URL parameter with the specified value. Updates the browser's URL without reloading the page.
     * @param {string} key - The name of the parameter to update.
     * @param {*} value - The new value for the parameter.
     */
    updateParam(key, value) {
        this.url.searchParams.set(key, value);
        window.history.pushState({}, '', this.url);
    }

    /**
     * Deletes a parameter from the URL if it exists. Updates the browser's URL without reloading the page.
     * @param {string} key - The name of the parameter to delete.
     */
    deleteParam(key) {
        if (this.url.searchParams.has(key)) {
            this.url.searchParams.delete(key);

            this.url.search = this.url.searchParams.toString();

            window.history.pushState({}, '', this.url);
        }
    }
}