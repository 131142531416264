/**
 * A component that shows temporary and permanent system messages to the user.
 *
 * @param messages
 */
export default (messages) => ({
    temporaryMessages: [],
    permanentMessages: [],
    timeShownInSeconds: 3,
    init() {
        this.parseMessages(messages);
        this.removeTempMessagesByInterval();
    },
    parseMessages(messages) {
        // Add the isLeaving property
        messages.forEach((message) => {
            message.isLeaving = false;
        });
        // Seperate temporary and permanent messages
        this.permanentMessages = messages.filter(e => e.permanent);
        this.temporaryMessages = messages.filter(e => e.permanent === false);
    },
    removeTempMessagesByInterval() {
        if (this.temporaryMessages.length > 0) {
            let index = 0;
            const interval = setInterval(() => {
                this.temporaryMessages[index].isLeaving = true;
                index++;
                if (index === this.temporaryMessages.length) {
                    clearInterval(interval);
                    setInterval(() => {
                        this.temporaryMessages = [];
                    }, this.timeShownInSeconds * 1000);
                }
            }, this.timeShownInSeconds * 1000)
        }
    },
    removeMessage(index) {
        this.permanentMessages.splice(index, 1);
    },
    addFlashMessage() {
        const messages = Array.isArray(this.$event.detail) ? this.$event.detail : [this.$event.detail];
        this.parseMessages(messages);
        this.removeTempMessagesByInterval();
    },
})
