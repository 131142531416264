import {globalFetch} from "../../_utils.js";

export function locations({chosenOrganizationId = null, route = '/ajax/admin/getAllLocations'}) {
    return {
        chosenOrganizationId: chosenOrganizationId,
        organizations: [],
        locations: [],
        route: route,
        init() {
            globalFetch(this.route, {}, 'GET', true).then((organizations) => {
                organizations.forEach((org) => {
                    this.organizations.push({
                        id: org.id,
                        name: org.name
                    });
                    this.locations = this.locations.concat(org.locations)
                });
            });
        },
        setChosenOrganizationId(organizationId) {
            this.chosenOrganizationId = organizationId;
        },
        getOrganizations() {
            return this.organizations;
        },
        getLocations() {
            if (this.chosenOrganizationId !== null) {
                return this.locations.filter(e => e.organizationId === this.chosenOrganizationId)
            }
            return this.locations;
        }
    }
}