import Pikaday from 'pikaday';

/**
 *
 * @param inputName
 * @param config
 */
export default function ({
                             inputName = false,
                             config = {},
                             value = ''
                         }) {
    return {
        datepicker: null,
        inputName: inputName,
        value: value,
        init() {
            config.field = this.$refs.datepicker;
            config.format = 'DD-MM-YYYY';
            config.yearRange = [new Date().getFullYear() - 100, new Date().getFullYear()];
            config.container = this.$refs.datepickerContainer;
            this.datepicker = new Pikaday(config);
        }
    }
}
