import {uniq} from 'lodash';

export default function ({userId}) {
    return {
        allOrganizations: [],
        organizationCache: [],
        organizationLocations: [],
        locationsToRemove: [],
        locationsToAdd: [],
        loading: true,
        removingLocations: false,
        removingOrganization: false,
        queryOrganization: '',
        queryLocation: '',
        async init() {
            this.organizationLocations = await this.getUserOrganizationLocations();
        },
        async getUserOrganizationLocations() {
            this.loading = true;
            const userLocations = await this.fetchUserLocations();
            const organizationLocations = Object.values(userLocations);
            organizationLocations.forEach(organization => {
                this.locationsToRemove[organization.id] = [];
            });
            this.loading = false;
            return organizationLocations;
        },
        async fetchUserLocations() {
            this.loading = true;

            return await fetch('/ajax/user/getUserLocations/' + userId)
                .then((response) => {
                    return response.json();
                })
                .catch((e) => {
                    this.$dispatch('add-flash-message', {
                        message: 'Er is iets misgegaan bij het ophalen van de locaties van deze gebruiker',
                        type: 'error',
                        permanent: true
                    });
                    throw new Error(e.status);
                    return {};
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getUserLocationIds() {
            return this.organizationLocations.map(e => {
                return e.locations.map(e => e.id)
            }).flat();
        },
        async fetchAllOrganizations() {
            return await fetch('/ajax/organization/getAllOrganizationsAndLocations', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    excludeLocations: this.getUserLocationIds()
                })
            })
                .then((response) => {
                    return response.json();
                }).then((result) => {
                    this.organizationCache = Object.values(result);
                    this.allOrganizations = this.organizationCache;
                })
                .catch((e) => {
                    this.$dispatch('add-flash-message', {
                        message: 'Er is iets misgegaan bij het ophalen van de organisaties',
                        type: 'error',
                        permanent: true
                    });
                    throw new Error(e.status);
                    return {};
                });
        },
        async removeOrganization(organizationIndex) {
            this.removingOrganization = true;
            // Remove the locations for this user
            fetch(`/ajax/user/removeUserOrganization/${userId}/${this.organizationLocations[organizationIndex].id}`)
                .then(() => {
                    this.organizationLocations.splice(organizationIndex, 1);

                    this.$dispatch('add-flash-message', {
                        message: 'Organisatie succesvol verwijderd',
                        type: 'success',
                        permanent: true
                    });
                })
                .catch((e) => {
                    console.error(e)

                    this.$dispatch('add-flash-message', {
                        message: 'Er is iets misgegaan bij het verwijderen van de locaties van deze gebruiker',
                        type: 'error',
                        permanent: true
                    });
                })
                .finally(() => {
                    this.removingOrganization = false;
                });
        },
        async removeLocations(organizationIndex, locationIds) {
            if (locationIds.length === 0) {
                return
            }

            // Remove the locations for this user
            fetch('/ajax/user/removeUserLocations/' + userId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    locations: locationIds
                })
            })
                .then(() => {
                    // Remove the locations from the UI
                    const organization = this.organizationLocations[organizationIndex];
                    this.organizationLocations[organizationIndex].locations = organization.locations.filter((e) => !locationIds.includes(e.id))
                    this.locationsToRemove[organization.id] = [];

                    this.$dispatch('add-flash-message', {
                        message: 'Locatie(s) succesvol verwijderd',
                        type: 'success',
                        permanent: true
                    });
                })
                .catch((e) => {
                    console.error(e)

                    this.$dispatch('add-flash-message', {
                        message: 'Er is iets misgegaan bij het verwijderen van de locaties van deze gebruiker',
                        type: 'error',
                        permanent: true
                    });
                })
                .finally(() => {
                    this.removingLocations = false;
                    this.removingOrganization = false;
                });
        },
        openDialog() {
            this.$refs.addInstitutions.showModal();

            this.fetchAllOrganizations()
        },
        closeDialog() {
            this.$refs.addInstitutions.close();
        },
        toggleAllLocations(organizationId) {
            const organization = this.allOrganizations.filter(e => e.id === organizationId)[0],
                locationsIds = organization.locations.map(e => e.id);
            if (this.$el.checked) {
                this.locationsToAdd.push(...locationsIds);
            } else {
                this.locationsToAdd = this.locationsToAdd.filter(e => !locationsIds.includes(e))
            }

            this.locationsToAdd = uniq(this.locationsToAdd);
        },
        async addLocationsToUser() {
            if (this.locationsToAdd.length === 0) {
                return
            }
            this.loading = true;

            this.closeDialog();

            // Add the locations for this user
            fetch('/ajax/user/addUserLocations/' + userId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    locations: this.locationsToAdd
                })
            })
                .then((response) => {
                    return response.json();
                })
                .then(async (locations) => {
                    this.organizationLocations = await this.getUserOrganizationLocations();
                    this.fetchAllOrganizations();
                    this.$dispatch('add-flash-message', {
                        message: this.locationsToAdd.length === 1 ? 'Locatie succesvol toegevoegd' : 'Locatie(s) succesvol toegevoegd',
                        type: 'success',
                        permanent: true
                    });
                })
                .catch((e) => {
                    this.$dispatch('add-flash-message', {
                        message: 'Er is iets misgegaan bij het ophalen van de locaties van deze gebruiker',
                        type: 'error',
                        permanent: true
                    });
                    throw new Error(e.status);
                    return {};
                })
                .finally(e => {
                    this.locationsToAdd = [];
                })
        },
        getLocationName(locationId) {
            let locationName = '';
            this.allOrganizations.forEach(organization => {
                organization.locations.forEach((location) => {
                    if (location.id === locationId) {
                        locationName = location.name;
                    }
                });
            });
            return locationName;
        },
        search() {
            this.allOrganizations = this.organizationCache;
            if (this.queryOrganization !== '') {
                this.allOrganizations = this.allOrganizations.filter(organization => {
                    return organization.name.toLowerCase().includes(this.queryOrganization)
                })
            }

            if (this.queryLocation !== '') {
                const regex = new RegExp(this.queryLocation, "i");
                this.allOrganizations = this.allOrganizations
                    .map(org => ({
                        ...org,
                        locations: org.locations.map(location => ({
                            ...location,
                            highlighted: regex.test(location.name)
                        })),
                        hasMatchingLocation: org.locations.some(location => regex.test(location.name))
                    }))
                    .filter(org => org.hasMatchingLocation);
            }
        },
    }
}